
import { defineComponent, PropType } from 'vue';
import IBalance from '@/models/balance';
import { getDateWithLongMonth } from '@/helpers';

export default defineComponent({
  name: 'BalancePanel',

  props: {
    balance: Object as PropType<IBalance>,
  },

  data() {
    return {
      getDateWithLongMonth,
    };
  },
});
