import ITariff from './tariff';

import { PeriodTypeCode } from '@/types';
import jsonApi from '@/api/jsonapi';

interface ITariffVariant {
  id: string,
  period: PeriodTypeCode,
  price: string,
  tariff?: ITariff,
}

jsonApi.define<ITariffVariant>(
  'tariff_variant',
  {
    period: 'month',
    price: '',
    tariff: {
      jsonApi: 'hasOne',
      type: 'tariffs',
    },
  },
);

export default ITariffVariant;
