
import { defineComponent } from 'vue';

import RestorePasswordForm from '@/components/public/RestorePassword/RestorePasswordForm.vue';

export default defineComponent({
  name: 'RestorePasswordView',

  components: {
    RestorePasswordForm,
  },
});
