
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia';
import vClickOutside from 'click-outside-vue3';
import useAuth from '@/stores/auth';

export default defineComponent({
  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      links: [
        {
          title: 'Баланс и платежи',
          route: 'projects-balances',
        },
      ],
      isShowDropdown: false,
    };
  },

  computed: {
    ...mapState(useAuth, ['authUser']),
  },

  methods: {
    ...mapActions(useAuth, ['signOut']),

    toggleDropdown() {
      this.isShowDropdown = !this.isShowDropdown;
    },

    closeDropdown() {
      this.isShowDropdown = false;
    },

    async signOutAndRedirectToPublic() {
      const result = await this.signOut();

      if (result === true) {
        this.$router.push({ name: 'public-home' });
      }
    },
  },
});
