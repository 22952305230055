import { defineStore } from 'pinia';
import { ReplenishmentTypeCode } from '@/types';
import IReplenishment from '@/models/replenishment';

const useReplenishment = defineStore({
  id: 'replenishment',

  state() {
    return {
      replenishmentTypesCodes: [
        'bank_card',
      ] as ReplenishmentTypeCode[],
      replenishments: [{
        id: '1',
        logoPath: `${process.env.BASE_URL}images/replenishment/card.jpg`,
        type: 'bank_card',
        title: 'Банковские карты',
        description: `
          Платежи осуществляются через эквайринг от Сбербанка.
          Оплату возможно совершить картами VISA, MasterCard,
          VISA Electron и Maestro.
        `,
        notice: `
          Комиссии нет. Средства будут зачислены на баланс
          в течение 1 минуты.
        `,
      }] as IReplenishment[],
    };
  },
});

export default useReplenishment;
