import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

import { notify } from '@kyvg/vue3-notification';
import { IJsonApiError, Primitive } from '@/types';

export function isSchemaFieldName<TValues extends Record<string, unknown>>(
  schema: TValues,
  fieldName: string,
): fieldName is Extract<keyof TValues, string> {
  return Object.keys(schema).includes(fieldName);
}

export function showServerErrors<TValues extends Record<string, unknown>>(
  errorResponse: IJsonApiError,
  defaultErrorMessage: string,
  fields: TValues,
  setFieldError: (field: Extract<keyof TValues, string>, message: string) => void,
): void {
  const { status, errors } = errorResponse;

  if (status === 400) {
    errors.forEach((error) => {
      // Берем последний элемент чтобы одинаково обрабатывать ошибки с pointer
      // /data/attributes/amount и /email
      const fieldName = error.source.pointer.split('/').pop();

      if (fieldName && isSchemaFieldName(fields, fieldName)) {
        setFieldError(fieldName, error.detail);
      }
    });

    return;
  }

  let errorMessage = defaultErrorMessage;

  if (status === 422) {
    errorMessage = errors[0].title;
  }

  notify({
    type: 'error',
    group: 'foo',
    title: errorMessage,
  });
}

export function getFormattedDate(unformattedDate: string): string {
  const unformatted = new Date(unformattedDate);
  return unformatted.toLocaleDateString('ru').split('.').join('-');
}

export function getFormattedDateTime(unformattedDate: string): string {
  const unformatted = new Date(unformattedDate);
  const date = getFormattedDate(unformattedDate);
  const time = unformatted.toLocaleTimeString(
    'ru', { hour: '2-digit', minute: '2-digit' },
  );

  return `${date} ${time}`;
}

export function getISODate(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const UTCDate = new Date(Date.UTC(year, month, day));

  return UTCDate.toISOString().slice(0, 10);
}

export function getDateWithLongMonth(unformattedDate: string): string {
  const date = new Date(unformattedDate);
  return date.toLocaleString('ru', { day: 'numeric', month: 'long' });
}

export function isObject(value: unknown): boolean {
  return value instanceof Object && !Array.isArray(value);
}

export function isArrayOfValues(param: unknown, values: Primitive[]): boolean {
  return Array.isArray(param) && param.every((value) => values.includes(value));
}

export function omitValues(
  obj: Record<string, any>,
  values: Primitive[],
): Record<string, any> {
  const result = obj;

  Object.entries(obj).forEach(([key, value]) => {
    if (values.includes(value) || isArrayOfValues(value, values)) {
      delete result[key];
    } else if (isObject(value)) {
      result[key] = omitValues(value, values);
    }
  });

  return result;
}

export function convertObjectKeysToCamelCase(element: Record<string, any>): any {
  const convertedDataItem: any = {};

  Object.keys(element).forEach((key) => {
    const convertedKey = camelCase(key);

    if (typeof element[key] === 'object') {
      convertedDataItem[convertedKey] = convertObjectKeysToCamelCase(element[key]);
    } else {
      convertedDataItem[convertedKey] = element[key];
    }
  });

  return convertedDataItem;
}

export function convertObjectKeysToSnakeCase(element: Record<string, any>): any {
  const convertedDataItem: any = {};

  Object.keys(element).forEach((key) => {
    const convertedKey = snakeCase(key);
    convertedDataItem[convertedKey] = element[key];
  });

  return convertedDataItem;
}
