import jsonApi from '@/api/jsonapi';

interface ITariff {
  id: string,
  name: string,
  description: string,
  slug: string,
}

jsonApi.define<ITariff>(
  'tariff',
  {
    name: '',
    description: '',
    slug: '',
  },
);

export default ITariff;
