import { defineStore } from 'pinia';
import AuthApi from '@/api/auth';
import IBalance from '@/models/balance';
import IProject from '@/models/project';
import IUser from '@/models/user';
import BalanceApi from '@/api/balance';
import { user } from '@/stubs';

interface authState {
  authUser: IUser;
  authWasChecked: boolean;
  balances: IBalance[];
  isUserAuthorized: boolean;
}

const useAuth = defineStore({
  id: 'auth',

  state(): authState {
    return {
      authUser: user,
      authWasChecked: false,
      balances: [],
      isUserAuthorized: false,
    };
  },

  getters: {
    activatedProjects(state: authState): IProject[] {
      const projects: IProject[] = [];

      state.balances.forEach(({ project, activeTariffVariants }) => {
        if (project && activeTariffVariants?.length) {
          projects.push(project);
        }
      });

      return projects;
    },

    nonactivatedProjects(state: authState): IProject[] {
      const projects: IProject[] = [];

      state.balances.forEach(({ project, activeTariffVariants }) => {
        if (
          project
          && !activeTariffVariants?.length
        ) {
          projects.push(project);
        }
      });

      return projects;
    },
  },

  actions: {
    async getBalances() {
      this.balances = await BalanceApi.findAll({
        include: 'project,active_tariff_variants',
      });
    },

    async getAuthUser() {
      try {
        this.authUser = await AuthApi.getAuthUser();
        this.isUserAuthorized = true;
      } catch (error) {
        this.authUser = user;
        this.isUserAuthorized = false;
      }

      this.authWasChecked = true;
    },

    async signIn(email: string, password: string) {
      const result = await AuthApi.signIn(email, password);

      return result;
    },

    async signUp(
      email: string,
      password: string,
      name: string,
      inn: string,
    ) {
      const result = await AuthApi.signUp(email, password, name, inn);

      return result;
    },

    async confirmEmail(token: string) {
      const result = await AuthApi.confirmEmail(token);

      return result;
    },

    async requestPasswordRestore(email: string) {
      const result = await AuthApi.requestPasswordRestore(email);

      return result;
    },

    async restorePassword(token: string, password: string) {
      const result = await AuthApi.restorePassword(token, password);

      return result;
    },

    async signOut() {
      const result = await AuthApi.signOut();

      return result;
    },
  },
});

export default useAuth;
