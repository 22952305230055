import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import useAuth from '@/stores/auth';
import publicRoutes from './public';
import manageRoutes from './manage';

const routes: Array<RouteRecordRaw> = [
  ...publicRoutes,
  ...manageRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const authStore = useAuth();

  if (authStore.authWasChecked === false) {
    await authStore.getAuthUser();
    authStore.getBalances();
  }

  if (to.meta.requiresAuth && !authStore.isUserAuthorized) {
    return '/public/sign-in';
  }

  if (to.meta.requiresNoAuth && authStore.isUserAuthorized) {
    return '/public';
  }

  return true;
});

export default router;
