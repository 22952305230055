
import { defineComponent, PropType } from 'vue';
import IBalance from '@/models/balance';

export default defineComponent({
  name: 'ProjectsBalancesLayout',

  props: {
    balances: {
      type: Array as PropType<IBalance[]>,
      required: true,
    },
  },
});
