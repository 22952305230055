
import { defineComponent, PropType } from 'vue';
import { getFormattedDateTime } from '@/helpers';
import ITransaction from '@/models/transaction';

export default defineComponent({
  name: 'PaymentHistoryPanel',

  props: {
    transactions: {
      type: Array as PropType<ITransaction[]>,
      required: true,
    },
  },

  data() {
    return {
      getFormattedDateTime,
    };
  },
});
