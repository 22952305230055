import JsonApi from 'devour-client';
import { toJsonApiError } from './instance';
import toSnakeCaseMiddleware from '@/api/middlewares/to-snake-case-middleware';
import toCamelCaseMiddleware from '@/api/middlewares/to-camel-case-middleware';
import { convertObjectKeysToSnakeCase } from '@/helpers';
import { Attributes } from '@/types';

class JsonApiCustom extends JsonApi {
  define<T>(
    modelName: string,
    attributes: Attributes<T>,
    options?: Record<string, any>,
  ) {
    const convertedDataItem = convertObjectKeysToSnakeCase(attributes);

    super.define(modelName, convertedDataItem, options);
  }
}

const jsonApi = new JsonApiCustom({
  apiUrl: '/web-api',
});

jsonApi.insertMiddlewareBefore('axios-request', toSnakeCaseMiddleware);
jsonApi.insertMiddlewareAfter('response', toCamelCaseMiddleware);
jsonApi.headers['content-type'] = 'application/vnd.api+json';
jsonApi.replaceMiddleware('errors', {
  name: 'convert-to-json-api-error',
  error(payload: any) {
    if (payload.response) {
      return toJsonApiError(payload.response);
    }

    if (payload instanceof Error) {
      return payload;
    }

    return null;
  },
});

export default jsonApi;
