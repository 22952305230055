import IProject from './project';
import IUser from './user';
import IActiveTariffVariant from './active_tariff_variant';

import jsonApi from '@/api/jsonapi';

interface IBalance {
  id: string,
  amount: number,
  project?: IProject,
  user?: IUser,
  activeTariffVariant?: IActiveTariffVariant,
  activeTariffVariants?: IActiveTariffVariant[],
}

jsonApi.define<IBalance>(
  'balance',
  {
    amount: 0,
    project: {
      jsonApi: 'hasOne',
      type: 'projects',
    },
    user: {
      jsonApi: 'hasOne',
      type: 'users',
    },
    activeTariffVariant: {
      jsonApi: 'hasOne',
      type: 'active_tariff_variants',
    },
    activeTariffVariants: {
      jsonApi: 'hasMany',
      type: 'active_tariff_variants',
    },
  },
);

export default IBalance;
