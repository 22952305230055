
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';

import useAuth from '@/stores/auth';
import { ILink } from '@/types';
import Menu from '@/components/Base/Menu/Menu.vue';
import SignInForm from '@/components/public/Auth/SignInForm/SignInForm.vue';

export default defineComponent({
  name: 'SignInView',

  components: {
    Menu,
    SignInForm,
  },

  data() {
    const links: ILink[] = [
      {
        route: 'sign-in',
        title: 'Вход',
      },
      {
        route: 'sign-up',
        title: 'Регистрация',
      },
    ];

    return {
      links,
    };
  },

  methods: {
    ...mapActions(
      useAuth,
      [
        'getAuthUser',
        'getBalances',
      ],
    ),

    async redirectToManage() {
      await this.getAuthUser();
      this.getBalances();

      this.$router.push('/manage/finances');
    },
  },
});
