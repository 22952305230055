
import { defineComponent, PropType } from 'vue';
import IProject from '@/models/project';

export default defineComponent({
  name: 'VerticalMenu',

  props: {
    projects: {
      type: Array as PropType<Array<IProject>>,
    },
  },
});
