
import { defineComponent, toRef } from 'vue';
import { ReplenishmentTypeCode } from '@/types';
import IInvoice from '@/models/invoice';
import useBalance from '@/stores/balance';
import useReplenishment from '@/stores/replenishment';
import ModalWindow from '@/components/Base/ModalWindow/ModalWindow.vue';
import ReplenishmentCard from './ReplenishmentCard.vue';
import InvoiceForm from './InvoiceForm.vue';

export default defineComponent({
  name: 'ReplenishmentLayout',

  components: {
    ModalWindow,
    ReplenishmentCard,
    InvoiceForm,
  },

  setup() {
    const balanceStore = useBalance();
    const replenishmentStore = useReplenishment();

    balanceStore.findAll({ include: 'project' });

    return {
      balances: toRef(balanceStore, 'balances'),
      replenishments: replenishmentStore.replenishments,
      replenishmentTypesCodes: replenishmentStore.replenishmentTypesCodes,
    };
  },

  data() {
    return {
      selectedReplenishmentType: null as ReplenishmentTypeCode | null,
      isInvoiceFormModalOpen: false,
    };
  },

  methods: {
    openInvoiceFormModal(replenishmentType: ReplenishmentTypeCode) {
      this.isInvoiceFormModalOpen = true;

      this.selectedReplenishmentType = replenishmentType;
    },

    closeReplenishmentModal() {
      this.isInvoiceFormModalOpen = false;

      this.selectedReplenishmentType = null;
    },

    performInvoice(invoice: IInvoice) {
      if (invoice.type === 'bank_card') {
        document.location.assign(invoice.formUrl);
      }
    },

    invoiceWasCreated(invoice: IInvoice) {
      this.performInvoice(invoice);
    },
  },
});
