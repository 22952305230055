
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';
import * as yup from 'yup';
import { useField, useForm } from 'vee-validate';

import VueButton from '@/components/Base/VueButton/VueButton.vue';
import useAuth from '@/stores/auth';
import { IJsonApiError } from '@/types';

const validationSchema = yup.object({
  password: yup.string().required().min(
    8,
    'Пароль должен содержать минимум 8 символов',
  ),
});
const presetValidation = () => {
  const { setFieldError, validate } = useForm({
    validationSchema,
  });

  const { errorMessage, value: password } = useField<string>('password');

  return {
    password,
    errorMessage,

    validate,
    setFieldError,
  };
};

export default defineComponent({
  name: 'RestorePasswordForm',

  components: {
    VueButton,
  },

  setup() {
    const validationData = presetValidation();

    return {
      ...validationData,
    };
  },

  data() {
    return {
      isLoading: false,
      isRequestExecuted: false,
    };
  },

  created() {
    const { token } = this.$route.query;

    if (typeof token !== 'string') {
      this.$router.push({ name: 'RequestPasswordRestore' });
    }
  },

  methods: {
    ...mapActions(useAuth, ['restorePassword']),

    async onSubmit() {
      const { valid } = await this.validate();
      const { token } = this.$route.query;

      if (!valid || typeof token !== 'string') {
        return;
      }

      this.isLoading = true;

      const errorResponse = await this.restorePassword(token, this.password);

      this.isLoading = false;

      if (!errorResponse) {
        this.$router.push({ name: 'RestorePasswordSuccess' });

        return;
      }

      this.showServerErrors(errorResponse);
    },

    showServerErrors(errorResponse: IJsonApiError) {
      const { errors, status } = errorResponse;
      const defaultErrorMessage = 'Ошибка восстановления пароля. Перезагрузите страницу и попробуйте снова.';
      let errorMessage = '';

      if (status === 400) {
        errors.forEach((error) => {
          const fieldName = error.source.pointer.slice(1);

          if (fieldName === 'password') {
            this.setFieldError(fieldName, error.detail);
          } else if (fieldName === 'token') {
            errorMessage = 'Неверный токен.';
          }
        });

        if (!errorMessage.length) {
          return;
        }
      }

      if (status === 422) {
        errorMessage = errors[0].title;
      }

      this.$notify({
        type: 'error',
        group: 'foo',
        title: errorMessage || defaultErrorMessage,
      });
    },
  },
});
