import { defineStore } from 'pinia';
import TransactionApi from '@/api/transaction';
import ITransaction from '@/models/transaction';

interface transactionState {
  transactions: ITransaction[],
}

const useTransaction = defineStore({
  id: 'transaction',

  state(): transactionState {
    return {
      transactions: [],
    };
  },

  actions: {
    async query(params: Record<string, unknown>) {
      this.transactions = await TransactionApi.query(params);
    },
  },
});

export default useTransaction;
