export const REPLENISHMENT_TYPES = {
  bank_card: 'Банковская карта',
} as const;

export const INVOICE = 'invoice';
export const REFUND = 'refund';
export const WRITE_OFF_BY_TARIFF = 'write_off_by_tariff';
export const WRITE_OFF_BY_SERVICE = 'write_off_by_service';

export const TRANSACTION_TYPES = {
  [INVOICE]: '+',
  [REFUND]: '+',
  [WRITE_OFF_BY_TARIFF]: '-',
  [WRITE_OFF_BY_SERVICE]: '-',
} as const;

export const PERIOD_TYPES = {
  month: 'месяц',
  year: 'год',
} as const;
