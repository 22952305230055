
import { defineComponent } from 'vue';

import ModalWindow from '@/components/Base/ModalWindow/ModalWindow.vue';
import RequestPasswordRestoreForm from '@/components/public/RestorePassword/RequestPasswordRestoreForm.vue';

export default defineComponent({
  name: 'RequestPasswordRestoreView',

  components: {
    ModalWindow,
    RequestPasswordRestoreForm,
  },

  methods: {
    close() {
      this.$router.push({ name: 'SignIn' });
    },
  },
});
