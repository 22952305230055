
import { defineComponent, PropType, toRef } from 'vue';
import BalancePanel from '@/components/manage/Dashboard/BalancePanel.vue';
import TariffPanel from '@/components/manage/Dashboard/TariffPanel.vue';
import PaymentHistoryPanel from '@/components/manage/Dashboard/PaymentHistoryPanel.vue';
import useBalance from '@/stores/balance';
import useTransaction from '@/stores/transaction';
import { WRITE_OFF_BY_TARIFF } from '@/constants';

export default defineComponent({
  name: 'DashboardLayout',

  components: {
    BalancePanel,
    TariffPanel,
    PaymentHistoryPanel,
  },

  props: {
    projectId: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  setup(props) {
    const balanceStore = useBalance();
    const transactionStore = useTransaction();

    balanceStore.getByProjectId(
      props.projectId,
      {
        include: 'project,active_tariff_variant.variant.tariff',
      },
    );
    transactionStore.query({
      filter: {
        balance_project_ids: [props.projectId],
        type: WRITE_OFF_BY_TARIFF,
      },
    });

    return {
      activeBalance: toRef(balanceStore, 'activeBalance'),
      transactions: toRef(transactionStore, 'transactions'),
    };
  },
});
