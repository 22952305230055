import jsonApi from '@/api/jsonapi';
import { ReplenishmentTypeCode } from '@/types';

interface IInvoice {
  id: string,
  amount: number,
  formUrl: string,
  number: number,
  type: ReplenishmentTypeCode,
  status: string
}

jsonApi.define<IInvoice>(
  'invoice',
  {
    amount: 0,
    formUrl: '',
    number: 0,
    type: 'bank_card',
    status: '',
  },
);

export default IInvoice;
