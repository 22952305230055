import { convertObjectKeysToSnakeCase } from '@/helpers';

export default {
  name: 'convert-to-snake-case',
  req: (payload: Record<any, any>): any => {
    if (payload.req.method === 'GET') {
      return payload;
    }

    const convertedAttributes = convertObjectKeysToSnakeCase(payload.req.data.data.attributes);
    const changedPayload = payload;

    changedPayload.req.data.data.attributes = convertedAttributes;

    return changedPayload;
  },
};
