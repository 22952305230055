import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["menu", _ctx.classModifier])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, i) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        class: _normalizeClass(["menu__item", [{
        'menu__item--disabled': link.isDisabled,
      }]]),
        key: i,
        to: link.route
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(link.title), 1)
        ]),
        _: 2
      }, 1032, ["class", "to"]))
    }), 128))
  ], 2))
}