
import { setLocale } from 'yup';
import { defineComponent } from 'vue';

setLocale({
  mixed: {
    required: 'Поле обязательно для заполнения',
  },
  string: {
    email: 'Введите корректный email',
  },
});

export default defineComponent({
  name: 'App',
});
