
import { defineComponent, toRef } from 'vue';
import { mapActions } from 'pinia';
import FiltersPanel from '@/components/manage/Finances/TransactionsHistory/FiltersPanel.vue';
import TransactionsList from '@/components/manage/Finances/TransactionsHistory/TransactionsList.vue';
import useTransaction from '@/stores/transaction';
import useProject from '@/stores/project';
import { getISODate, omitValues } from '@/helpers';

export default defineComponent({
  name: 'TransactionsHistoryLayout',

  components: {
    FiltersPanel,
    TransactionsList,
  },

  props: {
    projectId: Number,
    createdAtMin: String,
    createdAtMax: String,
  },

  setup(props) {
    const transactionStore = useTransaction();
    const projectStore = useProject();

    projectStore.findAll();

    return {
      params: {
        projectId: props.projectId,
        createdAtMin: props.createdAtMin,
        createdAtMax: props.createdAtMax,
      },
      transactions: toRef(transactionStore, 'transactions'),
      projects: toRef(projectStore, 'projects'),
    };
  },

  created() {
    this.$watch(
      () => this.$route.query,
      () => {
        this.fetchTransactions();
      },
      { immediate: true },
    );
  },

  methods: {
    ...mapActions(useTransaction, ['query']),

    fetchTransactions() {
      this.query(omitValues({
        filter: {
          balance_project_ids: [this.params.projectId],
          created_at_min: this.params.createdAtMin,
          created_at_max: this.params.createdAtMax,
        },
      }, ['', null, undefined]));
    },

    reloadWithParams() {
      const query = omitValues(this.params, ['', null, undefined]);

      this.$router.push(
        {
          name: 'transactions-history',
          query,
        },
      );
    },

    selectProject(projectId: number) {
      this.params.projectId = projectId;
      this.reloadWithParams();
    },

    selectMinDate(createdAtMin: Date) {
      this.params.createdAtMin = createdAtMin ? getISODate(createdAtMin) : '';
      this.reloadWithParams();
    },

    selectMaxDate(createdAtMax: Date) {
      this.params.createdAtMax = createdAtMax ? getISODate(createdAtMax) : '';
      this.reloadWithParams();
    },
  },
});
