
import { defineComponent } from 'vue';
import DashboardLayout from '@/components/manage/Dashboard/DashboardLayout.vue';

export default defineComponent({
  name: 'DashboardView',

  components: {
    DashboardLayout,
  },

  data() {
    return {
      projectId: +this.$route.params.projectId,
    };
  },
});
