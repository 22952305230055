import PublicHomeView from '@/views/public/Home.vue';
import SignInView from '@/views/public/SignIn.vue';
import SignUpView from '@/views/public/SignUp.vue';
import SignUpConfirmEmailView from '@/views/public/SignUpConfirmEmail.vue';
import RequestPasswordRestoreView from '@/views/public/RestorePassword/RequestPasswordRestore.vue';
import RestorePasswordView from '@/views/public/RestorePassword/RestorePassword.vue';
import RestorePasswordSuccessView from '@/views/public/RestorePassword/RestorePasswordSuccess.vue';

const publicRoutes = [
  {
    path: '/public',
    name: 'public-home',
    component: PublicHomeView,

    children: [
      {
        path: '/public/sign-in',
        name: 'SignIn',
        component: SignInView,
        meta: {
          requiresNoAuth: true,
        },

        children: [
          {
            path: '/public/request-password-restore',
            name: 'RequestPasswordRestore',
            component: RequestPasswordRestoreView,
            meta: {
              requiresNoAuth: true,
            },
          },
        ],
      },
      {
        path: '/public/sign-up',
        name: 'SignUp',
        component: SignUpView,
        meta: {
          requiresNoAuth: true,
        },
      },
      {
        path: '/public/confirm-email',
        name: 'SignUpConfirmEmail',
        component: SignUpConfirmEmailView,
        meta: {
          requiresNoAuth: true,
        },
      },
      {
        path: '/public/restore-password',
        name: 'RestorePassword',
        component: RestorePasswordView,
        meta: {
          requiresNoAuth: true,
        },
      },
      {
        path: '/public/restore-password-success',
        name: 'RestorePasswordSuccess',
        component: RestorePasswordSuccessView,
        meta: {
          requiresNoAuth: true,
        },
      },
    ],
  },
];

export default publicRoutes;
