import jsonApi from '@/api/jsonapi';
import { ReplenishmentTypeCode } from '@/types';

interface IReplenishment {
  id: string,
  logoPath: string,
  type: ReplenishmentTypeCode,
  title: string,
  description: string,
  notice: string,
}

jsonApi.define<IReplenishment>(
  'bank_card',
  {
    logoPath: '',
    type: 'bank_card',
    title: '',
    description: '',
    notice: '',
  },
);

export default IReplenishment;
