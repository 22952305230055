import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0ab9647d")
const _hoisted_1 = { class: "manage-home-view" }
const _hoisted_2 = { class: "manage-home-view__header" }
const _hoisted_3 = { class: "manage-home-view__content content" }
const _hoisted_4 = { class: "content__menu" }
const _hoisted_5 = { class: "content__content" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_VerticalMenu = _resolveComponent("VerticalMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        balances: _ctx.balances,
        selectedProjectId: _ctx.selectedProjectId
      }, null, 8, ["balances", "selectedProjectId"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_VerticalMenu, { projects: _ctx.activatedProjects }, null, 8, ["projects"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}