
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import Header from '@/components/manage/Base/Header/Header.vue';
import VerticalMenu from '@/components/manage/Base/VerticalMenu/VerticalMenu.vue';
import useAuth from '@/stores/auth';

export default defineComponent({
  name: 'ManageHomeView',

  components: {
    Header,
    VerticalMenu,
  },

  computed: {
    ...mapState(useAuth, [
      'activatedProjects',
      'balances',
    ]),
  },

  data() {
    return {
      selectedProjectId: 0,
    };
  },

  created() {
    this.$watch(
      () => this.$route,
      () => {
        if (this.$route.meta.displayBalance) {
          this.selectedProjectId = +this.$route.params.projectId;
        } else {
          this.selectedProjectId = 0;
        }
      },
      { immediate: true },
    );
  },
});
