import ITariffVariant from './tariff_variant';

import jsonApi from '@/api/jsonapi';

interface IActiveTariffVariant {
  id: string,
  startAt: string,
  nextBillAt: string,
  deactivateReason: string,
  isContinue: boolean,
  variant?: ITariffVariant,
}

jsonApi.define<IActiveTariffVariant>(
  'active_tariff_variant',
  {
    startAt: '',
    nextBillAt: '',
    deactivateReason: '',
    isContinue: false,
    variant: {
      jsonApi: 'hasOne',
      type: 'tariff_variants',
    },
  },
);

export default IActiveTariffVariant;
