import { defineStore } from 'pinia';
import IBalance from '@/models/balance';
import BalanceApi from '@/api/balance';
import { balance } from '@/stubs';

interface balanceState {
  balances: IBalance[],
  activeBalance: IBalance,
}

const useBalance = defineStore({
  id: 'balance',

  state(): balanceState {
    return {
      balances: [],
      activeBalance: balance,
    };
  },

  actions: {
    async findAll(params: Record<string, unknown>) {
      this.balances = await BalanceApi.findAll(params);
    },

    async findRecord(id: number) {
      this.activeBalance = await BalanceApi.findRecord(id);
    },

    async getByProjectId(projectId: number, params: Record<string, unknown>) {
      this.activeBalance = await BalanceApi.getByProjectId(projectId, params);
    },
  },
});

export default useBalance;
