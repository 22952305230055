
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField, useForm } from 'vee-validate';
import { mapActions } from 'pinia';

import VueButton from '@/components/Base/VueButton/VueButton.vue';
import useAuth from '@/stores/auth';
import { IJsonApiError } from '@/types';

const validationSchema = yup.object({
  email: yup.string().required().email(),
});
const presetValidation = () => {
  const { setFieldError, validate } = useForm({
    validationSchema,
  });

  const { errorMessage, value: email } = useField<string>('email');

  return {
    email,
    errorMessage,

    validate,
    setFieldError,
  };
};

export default defineComponent({
  name: 'RequestPasswordRestoreForm',

  components: {
    VueButton,
  },

  setup() {
    const validationData = presetValidation();

    return {
      ...validationData,
    };
  },

  data() {
    return {
      isLoading: false,
      isRequestExecuted: false,
    };
  },

  methods: {
    ...mapActions(useAuth, ['requestPasswordRestore']),

    async onSubmit() {
      const { valid } = await this.validate();

      if (!valid) {
        return;
      }

      this.isLoading = true;

      const errorResponse = await this.requestPasswordRestore(
        this.email,
      );

      this.isLoading = false;

      if (!errorResponse) {
        this.isRequestExecuted = true;

        return;
      }

      this.showServerErrors(errorResponse);
    },

    showServerErrors(errorResponse: IJsonApiError) {
      const { status, errors } = errorResponse;

      if (status === 400) {
        errors.forEach((error) => {
          const fieldName = error.source.pointer.slice(1);

          if (fieldName === 'email') {
            this.setFieldError(fieldName, error.detail);
          }
        });

        return;
      }

      let errorMessage = 'Ошибка восстановления пароля. Перезагрузите страницу и попробуйте снова.';

      if (status === 422) {
        errorMessage = errors[0].title;
      }

      this.$notify({
        type: 'error',
        group: 'foo',
        title: errorMessage,
      });
    },
  },
});
