
import { defineComponent } from 'vue';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { mapActions } from 'pinia';

import useAuth from '@/stores/auth';
import { showServerErrors } from '@/helpers';
import VueButton from '@/components/Base/VueButton/VueButton.vue';

const validationSchema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required().min(
    8,
    'Пароль должен содержать минимум 8 символов',
  ),
  name: yup.string().required(),
  inn: yup.string().required().matches(
    /^\d{10}$/,
    'ИНН должен содержать 10 цифр',
  ),
});
const presetValidation = () => {
  const { errors, validate, setFieldError } = useForm({
    validationSchema,
  });

  const fields = {
    email: useField<string>('email').value,
    password: useField<string>('password').value,
    name: useField<string>('name').value,
    inn: useField<string>('inn').value,
  };

  return {
    fields,
    errors,

    validate,
    setFieldError,
  };
};
const DEFAULT_ERROR_MESSAGE = 'Ошибка регистрации. Перезагрузите страницу и попробуйте снова.';

export default defineComponent({
  name: 'SignUpForm',

  components: {
    VueButton,
  },

  setup() {
    const validationData = presetValidation();

    return {
      ...validationData,
    };
  },

  data() {
    return {
      isAccepted: false,
      isLoading: false,
    };
  },

  methods: {
    ...mapActions(useAuth, ['signUp']),

    async onSubmit() {
      const { valid } = await this.validate();

      if (!valid) {
        return;
      }

      this.isLoading = true;

      const errorResponse = await this.signUp(
        this.fields.email.value,
        this.fields.password.value,
        this.fields.name.value,
        this.fields.inn.value,
      );

      this.isLoading = false;

      if (!errorResponse) {
        this.$emit('successfulSignUp', this.fields.email.value);

        return;
      }

      showServerErrors(
        errorResponse,
        DEFAULT_ERROR_MESSAGE,
        this.fields,
        this.setFieldError,
      );
    },
  },
});
