import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "transactions-history" }
const _hoisted_2 = { class: "transactions-history__filters-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FiltersPanel = _resolveComponent("FiltersPanel")!
  const _component_TransactionsList = _resolveComponent("TransactionsList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FiltersPanel, {
        projects: _ctx.projects,
        projectId: _ctx.projectId,
        createdAtMin: _ctx.createdAtMin,
        createdAtMax: _ctx.createdAtMax,
        onSelectProject: _ctx.selectProject,
        onSelectMinDate: _ctx.selectMinDate,
        onSelectMaxDate: _ctx.selectMaxDate
      }, null, 8, ["projects", "projectId", "createdAtMin", "createdAtMax", "onSelectProject", "onSelectMinDate", "onSelectMaxDate"])
    ]),
    _createVNode(_component_TransactionsList, { transactions: _ctx.transactions }, null, 8, ["transactions"])
  ]))
}