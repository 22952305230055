
import { defineComponent, PropType } from 'vue';
import { ILink } from '@/types';

const classModifiers = ['menu--auth', 'menu--responsive'];

export default defineComponent({
  name: 'Menu',

  props: {
    links: {
      type: Array as PropType<Array<ILink>>,
    },
    classModifier: {
      type: String,
      validator(value: string): boolean {
        return classModifiers.includes(value);
      },
    },
  },
});
