import jsonApi from '@/api/jsonapi';

interface IProject {
  id: string,
  name: string,
  description: string,
  slug: string,
}

jsonApi.define<IProject>(
  'project',
  {
    name: '',
    description: '',
    slug: '',
  },
);

export default IProject;
