import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-10ff6054")
const _hoisted_1 = { class: "dashboard-layout" }
const _hoisted_2 = { class: "dashboard-layout__description" }
const _hoisted_3 = { class: "dashboard-layout__layout" }
const _hoisted_4 = { class: "dashboard-layout__balance-tariff-column" }
const _hoisted_5 = { class: "dashboard-layout__balance-panel" }
const _hoisted_6 = { class: "dashboard-layout__tariff-panel" }
const _hoisted_7 = { class: "dashboard-layout__payment-history-panel" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalancePanel = _resolveComponent("BalancePanel")!
  const _component_TariffPanel = _resolveComponent("TariffPanel")!
  const _component_PaymentHistoryPanel = _resolveComponent("PaymentHistoryPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.activeBalance.project.name), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.activeBalance.project.description), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BalancePanel, { balance: _ctx.activeBalance }, null, 8, ["balance"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_TariffPanel, {
            activeTariffVariant: _ctx.activeBalance.activeTariffVariant
          }, null, 8, ["activeTariffVariant"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_PaymentHistoryPanel, { transactions: _ctx.transactions }, null, 8, ["transactions"])
      ])
    ])
  ]))
}