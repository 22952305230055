
import { defineComponent } from 'vue';
import ReplenishmentLayout from '@/components/manage/Finances/Replenishments/ReplenishmentLayout.vue';

export default defineComponent({
  name: 'ReplenishmentsView',

  components: {
    ReplenishmentLayout,
  },
});
