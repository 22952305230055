import IActiveTariffVariant from '@/models/active_tariff_variant';
import ITariffVariant from '@/models/tariff_variant';
import ITariff from '@/models/tariff';
import IBalance from '@/models/balance';
import IProject from '@/models/project';
import IUser from '@/models/user';

export const tariff: ITariff = {
  id: '0',
  name: 'Тариф',
  description: '',
  slug: '',
};

export const tariffVariant: ITariffVariant = {
  id: '0',
  period: 'month',
  price: '0',
  tariff,
};

export const activeTariffVariant: IActiveTariffVariant = {
  id: '0',
  startAt: '',
  nextBillAt: '',
  deactivateReason: '',
  isContinue: false,
  variant: tariffVariant,
};

export const project: IProject = {
  id: '0',
  name: '',
  description: '',
  slug: '',
};

export const user: IUser = {
  id: '0',
  name: '',
  inn: '',
  email: '',
};

export const balance: IBalance = {
  id: '0',
  amount: 0,
  project,
  user,
  activeTariffVariant,
};
