import jsonApi from '@/api/jsonapi';

interface IUser {
  id: string,
  name: string,
  email: string,
  inn: string,
}

jsonApi.define<IUser>(
  'user',
  {
    name: '',
    email: '',
    inn: '',
  },
);

export default IUser;
