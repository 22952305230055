import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestPasswordRestoreForm = _resolveComponent("RequestPasswordRestoreForm")!
  const _component_modal_window = _resolveComponent("modal-window")!

  return (_openBlock(), _createBlock(_component_modal_window, {
    class: "request-password-restore",
    onClose: _ctx.close
  }, {
    content: _withCtx(() => [
      _createVNode(_component_RequestPasswordRestoreForm)
    ]),
    _: 1
  }, 8, ["onClose"]))
}