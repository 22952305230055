import { instance } from '@/api/instance';
import jsonApi from '@/api/jsonapi';
import { IJsonApiError } from '@/types';
import IUser from '@/models/user';

export default class AuthApi {
  static async signIn(email: string, password: string): Promise<IJsonApiError | true> {
    try {
      await instance.post(
        '/sign-in',
        {
          email, password,
        },
      );
    } catch (error) {
      return error;
    }

    return true;
  }

  static async signUp(
    email: string,
    password: string,
    name: string,
    inn: string,
  ): Promise<IJsonApiError | void> {
    try {
      await instance.post(
        '/sign-up',
        {
          email, password, name, inn,
        },
      );
    } catch (error) {
      return error;
    }
  }

  static async signOut(): Promise<IJsonApiError | true> {
    try {
      await instance.post('/sign-out');
    } catch (error) {
      return error;
    }

    return true;
  }

  static async confirmEmail(token: string): Promise<IJsonApiError | void> {
    try {
      await instance.post(
        '/confirm-email',
        { token },
      );
    } catch (error) {
      return error;
    }
  }

  static async requestPasswordRestore(email: string): Promise<IJsonApiError | void> {
    try {
      await instance.post(
        '/request-password-restore',
        { email },
      );
    } catch (error) {
      return error;
    }
  }

  static async restorePassword(
    token: string,
    password: string,
  ): Promise<IJsonApiError | void> {
    try {
      await instance.post(
        '/restore-password',
        { token, password },
      );
    } catch (error) {
      return error;
    }
  }

  static async getAuthUser(): Promise<IUser> {
    const result = await instance.get('/user');

    return jsonApi.deserialize.resource.call(
      jsonApi,
      result.data.data,
      result.data.included,
    );
  }
}
