
import { defineComponent } from 'vue';

const BTN_CLASS = 'btn';

export default defineComponent({
  name: 'VueButton',

  props: {
    customClass: {
      type: String,
      default: '',
    },
    isFlat: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    label: String,
  },

  computed: {
    buttonClass(): Record<string, boolean> {
      return {
        [`${BTN_CLASS}--loading`]: this.isLoading,
        [`${BTN_CLASS}--disabled`]: this.isDisabled,
        [BTN_CLASS]: true,
        [this.customClass]: true,
      };
    },
  },
});
