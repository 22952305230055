
import { defineComponent } from 'vue';
import Header from '@/components/public/Base/Header/Header.vue';

export default defineComponent({
  name: 'PublicHomeView',

  components: {
    Header,
  },
});
