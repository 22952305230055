
import { defineComponent, PropType } from 'vue';
import { TRANSACTION_TYPES } from '@/constants';
import ITransaction from '@/models/transaction';
import { getFormattedDateTime } from '@/helpers';

export default defineComponent({
  name: 'TransactionsList',

  props: {
    transactions: {
      type: Array as PropType<ITransaction[]>,
      required: true,
    },
  },

  data() {
    return {
      TRANSACTION_TYPES,
      getFormattedDateTime,
    };
  },

  computed: {
    formattedTransactions() {
      const transactions: Record<string, ITransaction[]> = {};

      if (!this.transactions.length) {
        return transactions;
      }

      this.transactions.forEach((transaction: ITransaction) => {
        const createdAt = new Date(transaction.createdAt);
        const month = createdAt.toLocaleString('ru', { month: 'long' });
        const year = createdAt.getFullYear();

        const date = `${month.charAt(0).toUpperCase()}${month.slice(1)} ${year}`;

        if (transactions[date]) {
          transactions[date].push(transaction);
        } else {
          transactions[date] = [transaction];
        }
      });

      return transactions;
    },
  },
});
