import jsonApi from '@/api/jsonapi';
import IBalance from '@/models/balance';

export default class BalanceApi {
  static async findAll(params: Record<string, unknown> = {}): Promise<IBalance[]> {
    return (
      await jsonApi.findAll<IBalance>('balance', params)
    ).data;
  }

  static async findRecord(id: number): Promise<IBalance> {
    const balance: IBalance = {
      id: '1',
      amount: 1000,
      project: {
        id: String(id),
        name: 'Loy',
        description: '',
        slug: '',
      },
    };

    return Promise.resolve(balance);
  }

  static async getByProjectId(
    projectId: number,
    params: Record<string, unknown>,
  ): Promise<IBalance> {
    jsonApi.builderStack = [{
      path: `projects/${projectId}/balance`,
    }];

    return (
      await jsonApi.get<IBalance>(params)
    ).data;
  }
}
