
import { defineComponent, PropType } from 'vue';
import IActiveTariffVariant from '@/models/active_tariff_variant';
import { PERIOD_TYPES } from '@/constants';
import { getFormattedDate } from '@/helpers';

export default defineComponent({
  name: 'TariffPanel',

  props: {
    activeTariffVariant: Object as PropType<IActiveTariffVariant>,
  },

  data() {
    return {
      PERIOD_TYPES,
      getFormattedDate,
    };
  },
});
