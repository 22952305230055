
import { defineComponent } from 'vue';
import vClickOutside from 'click-outside-vue3';

export default defineComponent({
  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    title: String,
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
});
