import jsonApi from '@/api/jsonapi';
import { ReplenishmentTypeCode } from '@/types';
import IInvoice from '@/models/invoice';

export default class AuthApi {
  static async create(
    balanceId: number, invoiceType?: ReplenishmentTypeCode, amount?: number,
  ): Promise<IInvoice> {
    const invoice = (await jsonApi.one('balance', balanceId)
      .all('invoice')
      .post<IInvoice>({
        type: invoiceType,
        amount,
      })).data;

    return invoice;
  }
}
