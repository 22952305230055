import ManageHomeView from '@/views/manage/Home.vue';
import FinancesView from '@/views/manage/Finances.vue';
import ReplenishmentsView from '@/views/manage/Finances/Replenishments.vue';
import TransactionsHistoryView from '@/views/manage/Finances/TransactionsHistory.vue';
import ProjectsBalancesView from '@/views/manage/Finances/ProjectsBalances.vue';
import DashboardView from '@/views/manage/Dashboard.vue';
import ProjectsView from '@/views/manage/Projects.vue';

const manageRoutes = [
  {
    path: '/manage',
    name: 'manage-home',
    component: ManageHomeView,
    meta: {
      requiresAuth: true,
    },

    children: [
      // Баланс
      {
        path: '/manage/finances',
        name: 'finances',
        component: FinancesView,
        redirect: ({ name: 'projects-balances' }),
        meta: {
          requiresAuth: true,
        },

        children: [
          {
            path: '/manage/finances/projects-balances',
            name: 'projects-balances',
            component: ProjectsBalancesView,
          },
          {
            path: '/manage/finances/replenishments',
            name: 'replenishments',
            component: ReplenishmentsView,
          },
          {
            path: '/manage/finances/transactions-history',
            name: 'transactions-history',
            component: TransactionsHistoryView,
          },
        ],
      },

      // Проекты
      {
        path: '/manage/projects',
        name: 'projects',
        component: ProjectsView,
      },

      // Проект
      {
        path: '/manage/projects/:projectId',
        name: 'project',
        component: DashboardView,
        redirect: ({ name: 'project-dashboard' }),
        meta: {
          requiresAuth: true,
          displayBalance: true,
        },

        children: [
          {
            path: '/manage/projects/:projectId/dashboard',
            name: 'project-dashboard',
            component: DashboardView,
          },
        ],
      },
    ],
  },
];

export default manageRoutes;
