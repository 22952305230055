import { convertObjectKeysToCamelCase } from '@/helpers';

export default {
  name: 'convert-to-camel-case',
  res: (payload: Record<any, any>): any => {
    if (!payload.data.length) {
      return {
        ...payload,
        data: convertObjectKeysToCamelCase(payload.data),
      };
    }

    const data: any = [];

    payload.data.forEach((element: any) => {
      const convertedElement = convertObjectKeysToCamelCase(element);

      data.push(convertedElement);
    });

    return {
      ...payload,
      data,
    };
  },
};
