
import { defineComponent, PropType } from 'vue';
import { useForm, useField } from 'vee-validate';

import * as yup from 'yup';

import useInvoice from '@/stores/invoice';
import { showServerErrors } from '@/helpers';
import { ReplenishmentTypeCode } from '@/types';
import IBalance from '@/models/balance';
import VueButton from '@/components/Base/VueButton/VueButton.vue';
import { isJsonApiError } from '@/api/instance';
import { REPLENISHMENT_TYPES } from '@/constants';

const validationSchema = yup.object({
  balanceId: yup.number().required(),
  type: yup.string().required(),
  amount: yup.number().required(),
});

const presetValidation = () => {
  const { setFieldError, validate, errors } = useForm({
    validationSchema,
  });

  const fields = {
    balanceId: useField<number>('balanceId').value,
    type: useField<ReplenishmentTypeCode>('type').value,
    amount: useField<number>('amount').value,
  };

  return {
    fields,
    errors,

    validate,
    setFieldError,
  };
};

const DEFAULT_ERROR_MESSAGE = 'Ошибка создания пополнения. Перезагрузите страницу и попробуйте снова.';

export default defineComponent({
  name: 'InvoiceForm',

  props: {
    replenishmentTypesCodes: {
      type: Array as PropType<ReplenishmentTypeCode[]>,
      required: true,
    },
    balances: {
      type: Array as PropType<IBalance[]>,
      required: true,
    },
  },

  components: {
    VueButton,
  },

  setup() {
    const validationData = presetValidation();
    const invoiceStore = useInvoice();

    return {
      ...validationData,

      invoiceStore,
    };
  },

  data() {
    return {
      REPLENISHMENT_TYPES,
      isLoading: false,
    };
  },

  methods: {
    async onSubmit() {
      const { valid } = await this.validate();

      if (!valid) {
        return;
      }

      this.isLoading = true;
      let invoice;

      try {
        invoice = await this.invoiceStore.create(
          this.fields.balanceId.value,
          this.fields.type.value,
          this.fields.amount.value,
        );
      } catch (error) {
        if (isJsonApiError(error)) {
          showServerErrors(
            error,
            DEFAULT_ERROR_MESSAGE,
            this.fields,
            this.setFieldError,
          );
        } else {
          throw error;
        }

        return;
      } finally {
        this.isLoading = false;
      }

      this.$emit('wasCreated', invoice);
    },

    cancel() {
      this.$emit('cancel');
    },
  },
});
