import axios, { AxiosResponse } from 'axios';
import {
  IJsonApiError,
  IAxiosJsonApiErrorResponse,
} from '@/types';

const JSON_CONTENT_TYPE = 'application/json';
export const JSON_API_ERROR_TYPE = 'jsonApiError';

export function isJsonApiErrorResponse(
  errorResponse: AxiosResponse | undefined,
): errorResponse is IAxiosJsonApiErrorResponse {
  return [
    'application/json',
    'application/vnd.api+json',
  ].includes(
    errorResponse?.headers['content-type'],
  );
}

export function isJsonApiError(
  error: Error | IJsonApiError,
): error is IJsonApiError {
  return (error as IJsonApiError).type === JSON_API_ERROR_TYPE;
}

export function toJsonApiError(
  errorResponse: AxiosResponse | undefined,
): IJsonApiError {
  if (isJsonApiErrorResponse(errorResponse)) {
    return {
      type: JSON_API_ERROR_TYPE,
      status: errorResponse.status,
      errors: errorResponse.data.errors,
    };
  }

  return {
    type: JSON_API_ERROR_TYPE,
    status: errorResponse?.status,
    errors: [
      {
        source: {
          pointer: '/',
        },
        title: '',
        detail: '',
      },
    ],
  };
}

export const instance = axios.create({
  headers: { Accept: '*/*', 'Content-Type': JSON_CONTENT_TYPE },
  baseURL: '/web-api/',
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const jsonApiError = toJsonApiError(error?.response);

    return Promise.reject(jsonApiError);
  },
);
