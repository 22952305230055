import { defineStore } from 'pinia';
import ProjectApi from '@/api/project';
import IProject from '@/models/project';
import { project } from '@/stubs';

interface projectState {
  projects: IProject[],
  activeProject: IProject,
}

const useProject = defineStore({
  id: 'project',

  state(): projectState {
    return {
      projects: [],
      activeProject: project,
    };
  },

  actions: {
    async findAll() {
      this.projects = await ProjectApi.findAll();
    },

    async findRecord(id: number) {
      this.activeProject = await ProjectApi.findRecord(id);
    },
  },
});

export default useProject;
