
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';
import useAuth from '@/stores/auth';

export default defineComponent({
  name: 'SignUpConfirmEmail',

  data() {
    return {
      isRequestExecuted: false,
      isSuccess: true,
    };
  },

  methods: {
    ...mapActions(useAuth, ['confirmEmail']),
  },

  async created() {
    const { token } = this.$route.query;

    if (typeof token !== 'string') {
      return;
    }

    const errorResponse = await this.confirmEmail(token);

    this.isRequestExecuted = true;
    this.isSuccess = !errorResponse;
  },
});
