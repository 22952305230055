import IProject from './project';

import jsonApi from '@/api/jsonapi';
import { INVOICE } from '@/constants';
import { TransactionTypeCode } from '@/types';

interface ITransaction {
  id: string,
  amount: number,
  createdAt: string,
  description: string,
  type: TransactionTypeCode,
  project?: IProject,
}

jsonApi.define<ITransaction>(
  'transaction',
  {
    amount: 0,
    createdAt: '',
    description: '',
    type: INVOICE,
    project: {
      jsonApi: 'hasOne',
      type: 'transactions',
    },
  },
);

export default ITransaction;
