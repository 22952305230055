import { defineStore } from 'pinia';
import InvoiceApi from '@/api/invoice';
import { ReplenishmentTypeCode } from '@/types';
import IInvoice from '@/models/invoice';

const useInvoice = defineStore({
  id: 'invoice',

  actions: {
    create(
      balanceId: number,
      invoiceType: ReplenishmentTypeCode,
      amount: number,
    ): Promise<IInvoice> {
      return InvoiceApi.create(balanceId, invoiceType, amount);
    },
  },
});

export default useInvoice;
