
import { defineComponent, PropType } from 'vue';
import VueButton from '@/components/Base/VueButton/VueButton.vue';
import IReplenishment from '@/models/replenishment';

export default defineComponent({
  name: 'ReplenishmentCard',

  props: {
    replenishment: {
      type: Object as PropType<IReplenishment>,
      required: true,
    },
  },

  components: {
    VueButton,
  },

  methods: {
    selectReplenishmentType() {
      this.$emit('selectReplenishmentType', this.replenishment.type);
    },
  },
});
