
import { defineComponent } from 'vue';
import DatePicker from 'vue2-datepicker';
import '@/styles/global/datepicker.scss';
// eslint-disable-next-line
import ru from 'date-format-parse/lib/locale/ru';

DatePicker.locale('ru', {
  formatLocale: ru,
  yearFormat: 'YYYY',
  monthFormat: 'MMM',
  monthBeforeYear: true,
});

export default defineComponent({
  props: {
    projects: Array,
    projectId: String,
    createdAtMin: String,
    createdAtMax: String,
  },

  components: {
    DatePicker,
  },

  data() {
    return {
      minDate: this.createdAtMin ? new Date(this.createdAtMin) : null,
      maxDate: this.createdAtMax ? new Date(this.createdAtMax) : null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },
});
