import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionsHistoryLayout = _resolveComponent("TransactionsHistoryLayout")!

  return (_openBlock(), _createBlock(_component_TransactionsHistoryLayout, {
    projectId: _ctx.params.projectId,
    createdAtMin: _ctx.params.createdAtMin,
    createdAtMax: _ctx.params.createdAtMax
  }, null, 8, ["projectId", "createdAtMin", "createdAtMax"]))
}