
import { defineComponent } from 'vue';
import TransactionsHistoryLayout from '@/components/manage/Finances/TransactionsHistory/TransactionsHistoryLayout.vue';

export default defineComponent({
  name: 'TransactionsHistoryView',

  components: {
    TransactionsHistoryLayout,
  },

  data() {
    const { query } = this.$route;

    return {
      params: {
        projectId: query.projectId,
        createdAtMin: query.createdAtMin,
        createdAtMax: query.createdAtMax,
      },
    };
  },
});
