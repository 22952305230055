
import { defineComponent, toRef } from 'vue';
import ProjectsBalancesLayout from '@/components/manage/Finances/ProjectsBalances/ProjectsBalancesLayout.vue';
import useBalance from '@/stores/balance';

export default defineComponent({
  name: 'ProjectsBalancesView',

  components: {
    ProjectsBalancesLayout,
  },

  setup() {
    const balanceStore = useBalance();

    balanceStore.findAll({ include: 'project' });

    return {
      balances: toRef(balanceStore, 'balances'),
    };
  },
});
