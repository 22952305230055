
import { defineComponent } from 'vue';
import { ILink } from '@/types';
import Menu from '@/components/Base/Menu/Menu.vue';
import SignUpForm from '@/components/public/Auth/SignUpForm/SignUpForm.vue';

export default defineComponent({
  name: 'SignUpView',
  components: {
    Menu,
    SignUpForm,
  },
  data() {
    const links: ILink[] = [
      {
        route: 'sign-in',
        title: 'Вход',
      },
      {
        route: 'sign-up',
        title: 'Регистрация',
      },
    ];

    return {
      links,
      email: '',
    };
  },
  methods: {
    showSuccessMessage(email: string) {
      this.email = email;
    },
  },
});
