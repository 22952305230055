
import { defineComponent } from 'vue';
import Menu from '@/components/Base/Menu/Menu.vue';
import { ILink } from '@/types';

export default defineComponent({
  name: 'FinancesView',

  components: {
    Menu,
  },

  data() {
    const links: ILink[] = [
      {
        route: 'projects-balances',
        title: 'Балансы',
      },
      {
        route: 'replenishments',
        title: 'Пополнения',
      },
      {
        isDisabled: true,
        route: 'finances',
        title: 'Переводы',
      },
      {
        isDisabled: true,
        route: 'finances',
        title: 'Уведомления',
      },
      {
        route: 'transactions-history',
        title: 'История операций',
      },
      {
        isDisabled: true,
        route: 'finances',
        title: 'Услуги',
      },
      {
        isDisabled: true,
        route: 'finances',
        title: 'Отчетные документы',
      },
    ];

    return {
      links,
    };
  },
});
