
import { defineComponent, PropType } from 'vue';
import IBalance from '@/models/balance';
import ProfileMenu from '@/components/Base/ProfileMenu/ProfileMenu.vue';

export default defineComponent({
  name: 'Header',

  props: {
    balances: {
      type: Array as PropType<IBalance[]>,
      requiered: true,
    },

    selectedProjectId: Number,
  },

  components: {
    ProfileMenu,
  },

  computed: {
    selectedBalance() {
      return this.balances?.find(({ project }) => {
        if (project?.id) {
          return +project.id === this.selectedProjectId;
        }

        return false;
      });
    },
  },
});
