import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-67ce0012")
const _hoisted_1 = { class: "replenishment-card" }
const _hoisted_2 = { class: "replenishment-card__logo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "replenishment-card__information information" }
const _hoisted_5 = { class: "information__title" }
const _hoisted_6 = { class: "information__description" }
const _hoisted_7 = { class: "information__notice" }
const _hoisted_8 = { class: "information__go-to-pay" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueButton = _resolveComponent("VueButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.replenishment.logoPath,
        alt: "Логотип системы"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.replenishment.title), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.replenishment.description), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.replenishment.notice), 1),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_VueButton, {
          customClass: "primary-btn",
          label: "Пополнить баланс",
          onClick: _ctx.selectReplenishmentType
        }, null, 8, ["onClick"])
      ])
    ])
  ]))
}