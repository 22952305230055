import IProject from '@/models/project';

export default class ProjectApi {
  static async findAll(): Promise<IProject[]> {
    const projects: IProject[] = [
      {
        id: '1',
        name: 'Loy',
        description: `
          Удобная и эффективная бонусная программа лояльности,
          превращает случайных посетителей в постоянных клиентов
          и мотивирует их покупать больше ваших товаров и услуг.`,
        slug: '',
      },
      {
        id: '2',
        name: 'Greenmade',
        description: '',
        slug: '',
      },
    ];

    return Promise.resolve(projects);
  }

  static async findRecord(id: number): Promise<IProject> {
    const project: IProject = {
      id: String(id),
      name: 'Loy',
      description: `
        Удобная и эффективная бонусная программа лояльности,
        превращает случайных посетителей в постоянных клиентов
        и мотивирует их покупать больше ваших товаров и услуг.`,
      slug: '',
    };

    return Promise.resolve(project);
  }
}
